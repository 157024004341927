import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { StorageService } from './core/auth/storage.service';
import { DataService } from './shared/services/data.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SharedModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'flexijob-backoffice';
  
  loading: boolean = false;
  constructor(private dataService: DataService,
    private storage:StorageService) {

  }

  ngOnInit(): void {
    this.dataService.loadingStatus().subscribe(data => {
      if (data != null) {
        setTimeout(() => {
          this.loading = data;
        }, 50);
      }
    });

  }

}
