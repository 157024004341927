import type { HttpInterceptorFn } from '@angular/common/http';
import { StorageService } from '../auth/storage.service';
import { inject } from '@angular/core';

export const tokenInterceptor: HttpInterceptorFn = (request, next) => {
  
  const storageService = inject(StorageService);
  
  if (storageService.token) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${storageService.token}`,
      },
    });
  }



  return next(request);
};
